import React from "react"

import {
  SectionTitle,
  BodyText,
  TextSectionWrapper,
} from "../../../styles/project-styles"
const TextSection = ({
  title,
  text,
  colStart,
  colEnd,
  rowStart,
  rowEnd,
  style,
}) => {
  return (
    <TextSectionWrapper
      colStart={colStart}
      colEnd={colEnd}
      rowStart={rowStart}
      rowEnd={rowEnd}
      style={style}
    >
      <SectionTitle>{title}</SectionTitle>
      <BodyText>{text}</BodyText>
    </TextSectionWrapper>
  )
}

export default TextSection
