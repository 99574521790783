import React from "react"
import {
  SpreadContent,
  ProcessStep,
  StepIcon,
  StepText,
  StepTitle,
  StepBody,
} from "../../../styles/project-styles"
import { UnderstandIcon } from "../../../assets/svg/process-icons"

const Design = ({ text }) => {
  return (
    <SpreadContent>
      <ProcessStep>
        <StepText>
          <StepTitle>Design</StepTitle>
          <StepBody>{text}</StepBody>
        </StepText>
      </ProcessStep>
    </SpreadContent>
  )
}

export default Design
