import React from "react"
import {
  Title,
  SpreadTitleWrapper,
  SpreadContent,
} from "../../styles/project-styles"

const SpreadTitle = props => {
  const { text, ...gridProps } = props
  return (
    <SpreadContent {...gridProps}>
      {/* //SpreadTitleWrapper: styling like marginet */}
      <SpreadTitleWrapper>
        {/*Title: styling specific to content */}
        <Title>{text}</Title>
      </SpreadTitleWrapper>
    </SpreadContent>
  )
}

export default SpreadTitle
