import React from "react"
import { Container } from "../../styles/global-styles"
import { SpreadOuter, SpreadInner } from "../../styles/project-styles"
const Spread = ({ children, fluid }) => {
  return (
    <SpreadOuter>
      <Container fluid={fluid ? true : false}>
        <SpreadInner>{children}</SpreadInner>
      </Container>
    </SpreadOuter>
  )
}
export default Spread
