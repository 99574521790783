import React from "react"
import {
  SpreadContent,
  ProcessStep,
  StepText,
  StepTitle,
  StepBody,
} from "../../../styles/project-styles"

const Prototype = ({ text, rowStart }) => {
  return (
    <SpreadContent>
      <ProcessStep>
        <StepText>
          <StepTitle>Prototype</StepTitle>
          <StepBody>{text}</StepBody>
        </StepText>
      </ProcessStep>
    </SpreadContent>
  )
}
export default Prototype
