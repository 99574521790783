import React from "react"
import Layout from "../../components/layout"
import TextSection from "../../components/project/intro/text-section"
import ProjectDetails from "../../components/project/intro/project-details"
import Spread from "../../components/project/spread"
import {
  Hero,
  Video,
  VideoFrame,
  Image,
  ImageCaption,
  SpreadContent,
  ProjectTitle,
  Headline,
  Tagline,
  FullWidthImage,
  Close,
  SpreadText,
  SpreadImageGrid,
  projectWrapper,
} from "../../styles/project-styles"
import SpreadTitle from "../../components/project/spread-title"
import CaptionLeft from "../../components/project/caption-left"
import Understand from "../../components/project/process/understand"
import Design from "../../components/project/process/design"
import Prototype from "../../components/project/process/prototype"
import Img from "gatsby-image"
import SmoothScroll from "../../components/smooth-scroll"
import { motion } from "framer-motion"

const Conductor = ({ data }) => {
  const {
    conductorSplash,
    kellySikkema,
    hansEiskonen,
    ryunosukeKikuno,
    googleHandTracking,
    leapMotion,
    handGestures,
    designOption1,
    designOption2,
    conductorAnnotated,
    conductorAnnotated2,
  } = data
  return (
    <motion.div
      variants={projectWrapper}
      initial="open"
      animate="open"
      exit="close"
    >
      <Close to="/work/" />
      <Layout project>
        {/* Hero */}
        <Spread fluid>
          <Hero
            imgUrl={conductorSplash.childImageSharp.fluid.src}
            aspectRatio={1920 / 1225}
          />
          <ProjectTitle>
            <Headline>Conductor</Headline>
            <Tagline>
              A new modality for shaping sounds using hand gestures
            </Tagline>
          </ProjectTitle>
        </Spread>

        <Spread>
          {/* place in data folder */}
          <SpreadText colStart={8} colEnd="span 5" even>
            <TextSection
              title="Challenge"
              text="Modern synthesizers have a myriad of parameters and finding the right sound can be difficult. So to address this problem my friend Nicolas Huynh Thien and I asked ourselves: How might we use real-time hand-tracking to make synthesizer configuration more intuitive."
            />
            <TextSection
              title={"Outcome"}
              text="The outcome of our exploration is a software tool we call Conductor. Conductor is a new modality that allows musicians to control midi-enabled software and instruments using hand gestures."
            />
            <ProjectDetails
              type={"software"}
              contributions={"software engineering, UX design"}
              rowStart={3}
              alignSelf="end"
            />
          </SpreadText>
          <SpreadImageGrid colStart={1} colEnd="span 7" rowStart={1}>
            <Image colStart={1} colEnd="span 3" rowStart={1} rowEnd="span 2">
              <Img fluid={kellySikkema.childImageSharp.fluid} />
            </Image>
            <Image colStart={4} colEnd="span 3" rowStart={1} rowEnd="span 2">
              <Img fluid={hansEiskonen.childImageSharp.fluid} />
            </Image>
            <Image colStart={1} colEnd="span 6" rowStart={3} rowEnd="span 4">
              <Img fluid={ryunosukeKikuno.childImageSharp.fluid} />
              <ImageCaption>source: unsplash</ImageCaption>
            </Image>
          </SpreadImageGrid>
        </Spread>
        <Spread>
          <Video>
            <VideoFrame
              src="https://www.youtube.com/embed/A_40MEqupXA"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></VideoFrame>
          </Video>
          <SpreadContent colEnd="span 2" style={{ fontSize: "0.75rem" }}>
            Conductor Demo Video
          </SpreadContent>
        </Spread>
        <Spread>
          <SpreadTitle
            text="The Process"
            colStart={1}
            colEnd="span 4"
            rowStart={1}
            rowEnd="span 1"
          />

          <SpreadText colEnd="span 4">
            <Understand text="We observed that with only two hands it can be difficult to play and explore sounds at the same time. We also noted that all existing solutions require costly hardware (such as the leap motion). So we decided to focus on a software-based solution running directly in the web browser." />
            <Design text="Using real-time hand-tracking we were able to read several key landmarks of the users hand. From these values we derived other hand orientation values such as  rotation, spread, pitch, yaw, roll etc. Finally we mapped these derived oritentation values to midi control change messages, effectively turning the users hand into a multi-dimensional mouse." />
            <Prototype text="The app was built as a React.js application running in the browser. Before coding the app, I explored a few different layouts to ensure a good user experience. I divided the screen into two main areas: Hand Tracking monitor and Control Panel. The Hand Tracking Monitor is at the center of the page and gives visual feedback about the user’s hand orientation. The control panel allows the user to select a desired set of orientation values to use for sound shaping." />
          </SpreadText>

          <SpreadImageGrid colStart={6} colEnd="span 7">
            <Image colStart={1} colEnd="span 4" rowEnd="span 2" caption>
              <Img fluid={googleHandTracking.childImageSharp.fluid} />
              <ImageCaption>hand-tracking</ImageCaption>
            </Image>

            <Image colStart={5} colEnd="span 3" rowEnd="span 2" caption>
              <Img fluid={leapMotion.childImageSharp.fluid} />
              <ImageCaption>
                leap motion: a popular hardware-based alternative
              </ImageCaption>
            </Image>

            <Image colStart={1} colEnd="span 3" rowEnd="span 4">
              <Img fluid={handGestures.childImageSharp.fluid} />
              <ImageCaption>
                some of the hand orientation values we derived from the camera
                feed.
              </ImageCaption>
            </Image>

            <Image colStart={4} colEnd="span 4" rowStart={3} rowEnd="span 2">
              <Img fluid={designOption1.childImageSharp.fluid} />
            </Image>
            <Image colStart={4} colEnd="span 4" rowStart={5} rowEnd="span 2">
              <Img fluid={designOption2.childImageSharp.fluid} />
              <ImageCaption>
                ui mockups: exploring different configurations
              </ImageCaption>
            </Image>
          </SpreadImageGrid>
        </Spread>
        <Spread fluid>
          <FullWidthImage>
            <Img fluid={conductorAnnotated.childImageSharp.fluid} />
          </FullWidthImage>
        </Spread>

        <Spread>
          <Image colStart={2} colEnd="span 12" rowEnd="span 6">
            <Img fluid={conductorAnnotated2.childImageSharp.fluid} />
          </Image>
          <CaptionLeft colStart={1} colEnd={2} rowStart={6} rowEnd={6}>
            X position mapped to filter cutoff, Y position mapped to Resonance,
            Spread mapped to flanger rate
          </CaptionLeft>
        </Spread>
      </Layout>
    </motion.div>
  )
}

export default Conductor

export const pageQuery = graphql`
  query {
    conductorSplash: file(
      relativePath: { eq: "conductor/conductor-hero-2.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kellySikkema: file(
      relativePath: { eq: "conductor/kelly-sikkema-4qnhBQv4qcg-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hansEiskonen: file(
      relativePath: { eq: "conductor/hans-eiskonen-zEM2aybu3n4-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ryunosukeKikuno: file(
      relativePath: {
        eq: "conductor/ryunosuke-kikuno-aVOACNd1cc0-unsplash.jpg"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    googleHandTracking: file(
      relativePath: { eq: "conductor/google-hand-tracking.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leapMotion: file(relativePath: { eq: "conductor/leap-motion.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    handGestures: file(relativePath: { eq: "conductor/hand-gestures.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    designOption1: file(relativePath: { eq: "conductor/design-option1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    designOption2: file(relativePath: { eq: "conductor/design-option2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    conductorAnnotated: file(
      relativePath: { eq: "conductor/conductor-annotated.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    conductorAnnotated2: file(
      relativePath: { eq: "conductor/conductor-annotated-2.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
