import React from "react"

export const DesignIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56">
    <g data-name="design icon" transform="translate(-1432 -2036)">
      <circle
        cx="28"
        cy="28"
        r="28"
        fill="#363636"
        data-name="Ellipse 9528"
        transform="translate(1432 2036)"
      />
      <g fill="#f7f7f7" data-name="Group 77185">
        <path
          d="M1473.007 2051.218a4.085 4.085 0 00-5.975.217l-12.106 13.963a6.273 6.273 0 012.384 1.517 6.518 6.518 0 011.516 2.384l13.964-12.106a4.061 4.061 0 00.217-5.975z"
          data-name="Path 81774"
        />
        <path
          d="M1450.599 2068.216c-1.95 1.95-.991 5.294-4.242 7.74a.85.85 0 00.31 1.517c5.046 1.053 8.638-.712 10.557-2.632a4.728 4.728 0 000-6.656 4.663 4.663 0 00-6.625.031z"
          data-name="Path 81775"
        />
      </g>
    </g>
  </svg>
)

export const PrototypeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    viewBox="0 0 56 56"
  >
    <g
      id="prototype_icon"
      data-name="prototype icon"
      transform="translate(-1597 -2036)"
    >
      <circle
        id="Ellipse_9529"
        data-name="Ellipse 9529"
        cx="28"
        cy="28"
        r="28"
        transform="translate(1597 2036)"
        fill="#363636"
      />
      <g id="proto" transform="translate(1612 2051)">
        <g id="Group_77190" data-name="Group 77190" transform="translate(0 0)">
          <g
            id="Group_77189"
            data-name="Group 77189"
            transform="translate(0 0)"
          >
            <path
              id="Path_81778"
              data-name="Path 81778"
              d="M25.49,22.1,11.14,8.145a1.662,1.662,0,0,1-.473-1.45A5.686,5.686,0,0,0,9.1,1.582,5.208,5.208,0,0,0,5.371,0,5.428,5.428,0,0,0,2.057,1.233a.547.547,0,0,0,.063.9L5.473,4.141A2.984,2.984,0,0,1,3.864,6.507l-3-1.8a.592.592,0,0,0-.567,0,.55.55,0,0,0-.285.45A5.438,5.438,0,0,0,1.494,9.25a5.509,5.509,0,0,0,3.993,1.724,4.8,4.8,0,0,0,1.123-.205,1.643,1.643,0,0,1,1.6.441l13.9,14.285a2.856,2.856,0,0,0,2.033.843,2.2,2.2,0,0,0,2.195-2.2A2.86,2.86,0,0,0,25.49,22.1Z"
              transform="translate(0 0)"
              fill="#f7f7f7"
            />
            <path
              id="Path_81779"
              data-name="Path 81779"
              d="M20.909,6.584h1.419a.55.55,0,0,0,.491-.3l2.195-4.39a.546.546,0,0,0-.1-.633l-1.1-1.1a.547.547,0,0,0-.633-.1L18.79,2.253a.55.55,0,0,0-.3.491V4.162L13,9.654l2.454,2.387Z"
              transform="translate(1.266 0)"
              fill="#f7f7f7"
            />
            <path
              id="Path_81780"
              data-name="Path 81780"
              d="M8.873,12.589a.548.548,0,0,0-.368.142L.936,19.56a3.2,3.2,0,0,0-.008,4.512l.1.106a3.218,3.218,0,0,0,2.262.935,3.186,3.186,0,0,0,2.273-.95l6.89-7.437a.546.546,0,0,0,.165-.391,1.666,1.666,0,0,1,.022-.222L9.183,12.559A1.7,1.7,0,0,1,8.873,12.589Z"
              transform="translate(0 1.223)"
              fill="#f7f7f7"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const UnderstandIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56">
    <g data-name="understand icon" transform="translate(-1277 -2036)">
      <circle
        cx="28"
        cy="28"
        r="28"
        fill="#363636"
        data-name="Ellipse 9527"
        transform="translate(1277 2036)"
      />
      <g fill="#f7f7f7" transform="translate(1295 2048)">
        <path
          d="M9.315 23.445a2.375 2.375 0 01-2.368-2.368 8.148 8.148 0 015.763-7.815 3.39 3.39 0 002.447-3.394 5.21 5.21 0 10-10.42 0 2.368 2.368 0 11-4.736 0A10.111 10.111 0 0110.025 0a9.871 9.871 0 019.868 9.868 8.148 8.148 0 01-5.763 7.815 3.39 3.39 0 00-2.447 3.394 2.428 2.428 0 01-2.368 2.368z"
          data-name="Path 81777"
        />
        <circle
          cx="2.842"
          cy="2.842"
          r="2.842"
          data-name="Ellipse 9524"
          transform="translate(6.473 25.498)"
        />
      </g>
    </g>
  </svg>
)
