import React, { Children } from "react"
import { SpreadContent, CaptionLeftInner } from "../../styles/project-styles"

const CaptionLeft = props => {
  const { children, ...gridProps } = props
  return (
    <SpreadContent {...gridProps}>
      <CaptionLeftInner>{children}</CaptionLeftInner>
    </SpreadContent>
  )
}
export default CaptionLeft
