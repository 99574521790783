import React from "react"
import {
  Detail,
  DetailKey,
  DetailValue,
  SpreadContent,
} from "../../../styles/project-styles"

const ProjectDetails = props => {
  const { type, contributions, ...gridProps } = props
  return (
    <SpreadContent {...gridProps}>
      <Detail>
        <DetailKey>type of project</DetailKey>
        <DetailValue>{type}</DetailValue>
      </Detail>
      <Detail>
        <DetailKey>my contributions</DetailKey>
        <DetailValue>{contributions}</DetailValue>
      </Detail>
    </SpreadContent>
  )
}

export default ProjectDetails
