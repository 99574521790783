import React from "react"
import {
  SpreadContent,
  ProcessStep,
  StepIcon,
  StepText,
  StepTitle,
  StepBody,
} from "../../../styles/project-styles"
import { UnderstandIcon } from "../../../assets/svg/process-icons"

const Understand = ({ text }) => {
  return (
    <SpreadContent>
      <ProcessStep>
        {/* <StepIcon>
          <UnderstandIcon />
        </StepIcon> */}
        <StepText>
          <StepTitle>Understand</StepTitle>
          <StepBody>{text}</StepBody>
        </StepText>
      </ProcessStep>
    </SpreadContent>
  )
}

export default Understand
